import {Button, Card, Col, Form, Input, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";

function ForgotPassword () {

    const [email, setEmail] = useState({
        value: "",
        error: ""
    });

    const setInputValue = (event) => {
        setEmail(prevState => ({
            ...prevState,
            value: event.target.value,
            error: event.target.value === "" ? "Email is required." : ""
        }));
    }

    const checkEmail = async () => {
        if(email.value === "")
        {
            setEmail(prevState => ({
                ...prevState,
                error: "Email is required."
            }));
            return;
        }
        try{
            const response = await axios.post("password/email",{
                email: email.value
            });
            if(response.data.success)
            {
                toast.success(response.data.message);
                setEmail(prevState => ({
                    ...prevState,
                    value: "",
                    error: ""
                }));
            }else{
                toast.error(response.data.message);
            }
        }catch (e)
        {
            toast.error(e.response.data.message+". Try again later!");
        }
    }

    return <>
        <Row className="login-card">
            <Col sm={4}>
                <p className="logo text-center fs-2">Forgot your password?</p>
                <Card className="p-4">
                    <p className="text-center fw-bold fs-4">Email Address</p>
                    <Form>
                        <Input
                            type="email"
                            bsSize="sm"
                            value={email.value}
                            className="my-3 mb-0"
                            name="email"
                            placeholder="Email"
                            onChange={(event) => setInputValue(event)}
                        />
                        {email.error !== "" ? <p className="text-danger">{email.error}</p> : null}
                        <Button onClick={checkEmail} className="mt-3 btn btn-theme btn-sm w-100">
                            Next
                        </Button>
                        <Link
                            to={"/login"}
                            className="btn btn-sm btn-outline-inactive rounded-0 w-100 mt-2"
                        >
                            Login
                        </Link>
                    </Form>
                </Card>
            </Col>
        </Row>
    </>
}
export default ForgotPassword;