import {Button, Card, Col, Form, Input, Row} from "reactstrap";
import {Link, useParams} from "react-router-dom";
import {useState} from "react";
import axios from "axios";
import toast from "react-hot-toast";

function ResetPassword () {

    const {token} = useParams();

    const [inputFields, setInputFields] = useState({
        password: "",
        password_error: "",
        confirm_password: "",
        confirm_password_error: ""
    });

    const setInputValue = (event) => {
        setInputFields(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            [event.target.getAttribute("errorKey")]: event.target.value === "" ? event.target.getAttribute("errorMessage") : ""
        }));
    }

    const updatePassword = async () => {
        if(inputFields.password === "")
        {
            setInputFields(prevState => ({
                ...prevState,
                password_error: "Password is required."
            }));
            return;
        }
        if(inputFields.password.length < 8)
        {
            setInputFields(prevState => ({
                ...prevState,
                password_error: "Password length must be at least 8 characters."
            }));
            return;
        }
        if(inputFields.confirm_password === "")
        {
            setInputFields(prevState => ({
                ...prevState,
                confirm_password_error: "Confirm password is required."
            }));
            return;
        }
        if(inputFields.confirm_password !== inputFields.password)
        {
            setInputFields(prevState => ({
                ...prevState,
                confirm_password_error: "Confirm password does not match with new password."
            }));
            return;
        }
        try{
            const response = await axios.post("password/reset",{
                new_password: inputFields.password,
                confirm_password: inputFields.confirm_password,
                resetToken: token
            });
            if(response.data.success)
            {
                toast.success(response.data.message);
                setTimeout(() => {
                    window.location.href = '/';
                }, 3000);
                setInputFields(prevState => ({
                    ...prevState,
                    password: "",
                    password_error: "",
                    confirm_password: "",
                    confirm_password_error: ""
                }));
            }else{
                toast.error(response.data.message);
            }
        }catch (e)
        {
            toast.error(e.response.data.message);
        }
    }

    return <>
        <Row className="login-card">
            <Col sm={4}>
                <p className="logo text-center fs-2">Reset Password</p>
                <Card className="p-4">
                    <p className="text-center fw-bold fs-4">Update your password</p>
                    <Form>
                        <Input
                            type="password"
                            bsSize="sm"
                            className="my-3 mb-0"
                            name="password"
                            value={inputFields.password}
                            errorKey="password_error"
                            placeholder="New password"
                            errorMessage="Password is required."
                            onChange={(event) => setInputValue(event)}
                        />
                        {inputFields.password_error !== "" ? <p className="text-danger">{inputFields.password_error}</p> : null}
                        <Input
                            type="password"
                            bsSize="sm"
                            className="my-3 mb-0"
                            name="confirm_password"
                            value={inputFields.confirm_password}
                            errorMessage="Password confirmation is required."
                            errorKey="confirm_password_error"
                            placeholder="Confirm new password"
                            onChange={(event) => setInputValue(event)}
                        />
                        {inputFields.confirm_password_error !== "" ? <p className="text-danger">{inputFields.confirm_password_error}</p> : null}
                        <Button onClick={updatePassword} className="mt-3 btn btn-theme btn-sm w-100">
                            Update Password
                        </Button>
                        <Link
                            to={"/login"}
                            className="btn btn-sm btn-outline-inactive rounded-0 w-100 mt-2"
                        >
                            Login
                        </Link>
                    </Form>
                </Card>
            </Col>
        </Row>
    </>
}
export default ResetPassword;